import React from 'react';
import { connect } from 'react-redux';
import { ContactModalRedux } from './contact_modal_redux';
import { AkunModalRedux } from './akun_modal_redux';
import { ItemsModalRedux } from './items_modal_redux';
import { PurchaseModalRedux } from './purchase_modal_redux';
import { SaleModalRedux } from './sale_modal_redux';
import { ReceivableModalRedux } from './receivable_modal_redux';
import { UmCustomerModalRedux } from './um_customer_modal_redux';
import { SaleReturnModalRedux } from './sale_return_modal_redux';
import { GiroModalRedux } from './current_account_modal_redux';
import { PayableModalRedux } from './payable_modal_redux';
import { UmSupplierModalRedux } from './um_supplier_modal_redux';
import { PurchaseReturnModalRedux } from './purchase_return_modal_redux';
import { BOMRedux } from './bom_modal_redux';
import { Modal } from '../../redux/actions/global_components';

class ModalRedux extends React.Component {

  render() {
    const{Modal, modal}= this.props
    if(modal){
        if(modal.name_modal === 'contacts'){
        return (
          <ContactModalRedux Modal={Modal} modal={modal}/>      
        )}
        else if(modal.name_modal === 'accounts'){
        return (
          <AkunModalRedux Modal={Modal} modal={modal}/>      
        )} 
        else if(modal.name_modal === 'items'){
        return (
          <ItemsModalRedux Modal={Modal} modal={modal}/>      
        )}
        else if(modal.name_modal === 'purchase'){
        return (
          <PurchaseModalRedux Modal={Modal} modal={modal}/>      
        )}
        else if(modal.name_modal === 'sale'){
        return (
          <SaleModalRedux Modal={Modal} modal={modal}/>      
        )} 
        else if(modal.name_modal === 'receivables'){
        return (
          <ReceivableModalRedux Modal={Modal} modal={modal}/>      
        )} 
        else if(modal.name_modal === 'sale_advances'){
        return (
          <UmCustomerModalRedux Modal={Modal} modal={modal}/>      
        )} 
        else if(modal.name_modal === 'sale_returns'){
        return (
          <SaleReturnModalRedux Modal={Modal} modal={modal}/>      
        )}
        else if(modal.name_modal === 'current_accounts'){
        return (
          <GiroModalRedux Modal={Modal} modal={modal}/>      
        )}
        else if(modal.name_modal === 'payables'){
        return (
          <PayableModalRedux Modal={Modal} modal={modal}/>      
        )} 
        else if(modal.name_modal === 'purchase_advances'){
        return (
          <UmSupplierModalRedux Modal={Modal} modal={modal}/>      
        )}
        else if(modal.name_modal === 'purchase_return_details'){
        return (
          <PurchaseReturnModalRedux Modal={Modal} modal={modal}/>      
        )}
        else if(modal.name_modal === 'bom'){
        return (
          <BOMRedux Modal={Modal} modal={modal}/>      
        )}
        else{
          return null
        }
    }else{return null};
  }
}


function mapState(state) {
   return  {modal : state.global_components.action.params}
}

const actionCreators = {
  Modal : Modal
}

const connectedModalRedux = connect(mapState, actionCreators)(ModalRedux) 
export { connectedModalRedux as ModalRedux}




