import React from 'react'

 class Footer extends React.Component{



render() {
	return(
			<div className="footer">
                <div className="pull-right">
                     <span style={{fontSize: 12}}>by</span> <strong>Peni Regency : <u>V. 1.2.2</u></strong>
                </div>
                <div>
                    <strong>Copyright</strong>  <span style={{fontSize: 12}} >sistem informasi akuntansi © 2023 </span>
                </div>
            </div>
		);
	}
}


export {Footer}