
export function global_components(state = {}, action ={params : null}) {
  switch (action.type) {
    case 'MODAL_COMPONENTS':
      return {action}
    case 'DIRECT':
      return {action}
    default:
      return {action}
  }
}